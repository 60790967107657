import gql from 'graphql-tag';

export default gql`
query getAllItemsOfFolderById($id: Int!) {
  folders(where: {folder_id: {_eq: $id}}) {
    group_id
    child_folders {
      folder_id
      item_path
      name
      listed
      order_index
      slug
      created_at
      updated_at
    }
    child_projects {
      project_id
      item_path
      name
      order_index
      slug
      created_at
      updated_at
      published
      searchable
      thumbnails
      listed
      is_shared_template
      incompatible_item
      child_pages {
        published
        latest_page_version_id
        latest_published_page_version_id
      }
      projects_tags {
        tag { name }
      }
    }
    child_pages(order_by: {order_index: asc}) {
      page_id
      item_path
      name
      order_index
      slug
      created_at
      updated_at
      published
      latest_page_version_id
      latest_page_version {
        user {
          email
        }
      }
      latest_published_page_version_id
      thumbnails
      searchable
      listed
      incompatible_item
      is_shared_template
      pages_tags {
        tag {
          name
        }
      }
      keywords
    }
  }
}
`;
